.header {
    width: 100%;
    height: 5.5rem;
    top: 0;
    position: fixed;
    z-index: 999999;
    background: var(--lighterGray) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__inner-container {
    width: 92%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-logo {
    width: 7rem;
    height: auto;
}

.nav-logo img {
    width: 100%;
    height: 100%;
}

.nav {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav a {
    height: 100%;
    text-decoration: none;
    color: var(--dark);
    font-weight: 500;
    padding-bottom: 1.7px;
}

.nav__contact-us {
    border-radius: 30px;
    padding: 1rem 1.5rem;
    background: transparent linear-gradient(90deg, #0668E1 0%, #479CDE 100%) 0% 0% no-repeat padding-box;
    color: #fff !important;
}

.active {
    border-bottom: 1.7px solid var(--dark);
}