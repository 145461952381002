.brand-section-wrapper {
    width: 100%;
    height: 48rem;
    background-color: #000;
    position: relative;
    font-family: 'Bebas Neue', Calibri;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.brand-section-content {
    width: 92%;
    height: 100%;
    padding: 5rem 0;
    display: flex;
    justify-content: space-between;
}

.motto-container {
    width: 40%;
    height: 100%;
    z-index: 1;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.motto-container>h1 {
    width: 1px;
    margin: 0;
    font-size: 9rem;
    letter-spacing: 19px;
}

.motto-container>div>h2 {
    margin-top: 1rem;
    font-size: 2.5rem;
    font-weight: 100;
    letter-spacing: 5px;
    color: var(--peachCream);
}

.motto-container>div {
    width: 100%;
}

.delimiter {
    width: 100%;
    height: 1px;
    padding: 0;
    margin: 0;
    background-color: var(--peachCream);
}

.values-container {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    z-index: 1;
}

.values-list {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInImg {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

.image-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    background-image: url('/public/buildings.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    animation: fadeInImg 1s ease-in-out forwards;
}

@media (max-width: 992px) {
    .brand-section-content {
        padding-bottom: 0;
    }

    .brand-section-wrapper {
        height: inherit;
    }

    .brand-section-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center !important;
    }

    .motto-container {
        max-width: 50rem;
        width: 100%;
        align-items: center;
    }

    .motto-container>h1 {
        width: 100%;
        overflow: hidden;
        text-align: center;
        font-size: 6rem;
        padding-bottom: 2rem;
        letter-spacing: 10px;
        word-spacing: 5rem;
    }

    .motto-container>div>h2 {
        font-size: 2rem;
    }

    .arrows {
        display: none;
    }

    .values-container {
        width: 80%;
    }

    .values-list {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .motto-container>h1 {
        font-size: 5rem;
    }
}

@media (max-width: 576px) {
    .motto-container>div>h2 {
        margin-top: 1rem;
        font-size: 2rem;
        font-weight: 100;
    }
}

@media (max-width: 440px) {
    .motto-container>h1 {
        text-align: center;
        font-size: 5rem;
    }
}