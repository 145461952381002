.value-item-container-mobile, .value-item-container {
    color: #fff;
}

.value-item-container {
    width: 28rem;
    height: 5.7rem;
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.value-item-container-mobile {
    width: 12rem;
    margin: 1.01rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.value-item-container-mobile .delimiter {
    height: 3rem !important;
}

.value-item-container-mobile:last-child .delimiter {
    display: none;
}

.value-item-container>p {
    width: 75%;
    text-align: end;
    font-size: 3rem;
    word-spacing: 5px;
}

.icons {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delimiter {
    width: 1px;
    height: 100%;
    margin: 0 10px;
    background-color: #fff;
}

@media (max-width: 992px) {
    .value-item-container-mobile>p {
        letter-spacing: 2px;
        font-size: 1.5rem;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .value-item-container-mobile>p {
        font-weight: 100;
    }
}