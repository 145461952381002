.vision-section-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
}

.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 50rem;
    height: auto;
}

.vision-section-wrapper img {
    width: 100%;
    height: 100%;
}

.vision-section-text-wrapper {
    width: 30%;
}

.vision-section-text-wrapper h2 {
    font-size: 4rem;
    font-weight: bold;
}

.vision-section-text-wrapper p {
    width: 85%;
    margin-bottom: 5rem;
}

.vision-section-text-wrapper button {
    height: 3.5rem;
    margin-top: 5%;
    background-color: white;
    border: 2px solid;
    border-radius: 1rem;
    cursor: pointer;
}

@media (max-width: 992px) {
    .vision-section-wrapper {
        width: 100%;
        padding: 0 0 5rem 0;
    }

    .vision-section-text-wrapper {
        width: 85%;
    }

    .vision-section-text-wrapper p {
        width: 100%;
    }

    .vision-section-wrapper {
        flex-direction: column;
    }
}