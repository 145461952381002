.services-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services-section>div {
    width: 91%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.service {
    max-width: 45%;
    min-height: 15rem;
    margin: 1% 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000029;
}

.service h3, .service p {
    width: 100%;
    text-align: left;
}

.service h3 {
    margin: 0;
}

@media (max-width: 425px) {
    .service p {
        font-size: 1.5rem;

    }
}

@media (max-width: 576px) {
    .service {
        padding: 5%;
    }
}

@media (max-width: 992px) {
    .service {
        max-width: 95%;
        margin: 2% 0;
    }
}