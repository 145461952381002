.reasons-section-wrapper {
    height: 65rem;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.reasons-section-wrapper>h2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 3.2rem;
}

.section-body {
    display: flex;
    justify-content: center;
    gap: 10rem;
    margin-top: 13rem;
}

.card-wrapper {
    position: relative;
    width: 25rem;
    border-radius: 19px;
    background: transparent linear-gradient(180deg, #60A9EF 0%, #182127 100%) 0% 0% no-repeat padding-box;
}

.card-header {
    display: flex;
    justify-content: center;
    padding: 5rem 0 2rem 0;
}

.card-header > img {
    position: absolute;
    top: -3.5rem;
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
}

.card-body {
    background: #1C2B33 0% 0% no-repeat padding-box;
    color: white;
    padding: 1rem 2rem 1rem 2rem;
    height: 23rem;
}

.card-footer {
    padding: 3rem;
}

@media (max-width: 768px) {
    .reasons-section-wrapper {
        height: unset;
    }

    .reasons-section-wrapper>h2 {
        text-align: center;
    }

    .section-body {
        flex-direction: column;
        align-items: center;
        margin-top: 7rem;
        margin-bottom: 3rem;
    }
}