.footer {
    width: 100%;
    height: 20rem;
    bottom: 0;
    background: var(--dark);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer a {
    color: #fff;
}

.mobile-navigation {
    width: 100%;
    height: 70%;
    padding: 3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid var(--lightGray);

}

.mobile-navigation a {
    text-decoration: none;
    color: var(--lightGray);
}

.mobile-contacts {
    width: 75%;
    height: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.mobile-contacts>a {
    width: 5%;
}

.mobile-contacts>span {
    width: 100%;
    position: absolute;
    bottom: 1rem;
    text-align: center;
    color: var(--lightGray);
}

.footer__inner-container {
    width: 91%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.footer__inner-container>div {
    height: 50%;
    display: flex;
}


.footer__inner-container>div:first-child {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.footer__inner-container>div:last-child {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.contacts-element {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.logo {
    width: auto;
    height: 5rem;
}

@media (max-width: 992px) {
    .footer {
        height: 47rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .logo {
        height: 7rem;
    }
}