html {
  /* 10 px base */
  font-size: 62.5%;
  --fontColor: #1C2B33;
  scroll-behavior: smooth;
  --primary: #0080FB;
  --dark: #1C2B33;
  --backgroundColor: hsl(0, 0%, 95%);
  --peachCream: #FFDBCB;
  --lighterGray: #F2F2F2;
  --lightGray: #B5B5B5;
  --gray: #606060;
  --grayDark: #414141;
  --blue: #1AA8F3;
  --blueDark: #003CA2;
  cursor: default;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  background-color: var(--backgroundColor);
}

.gray-out {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

h2 {
  font-weight: 500;
}