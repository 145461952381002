.services-section-wrapper {
    width: 100%;
    min-height: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.services-section-wrapper h2 {
    color: var(--grayDark);
}

.company-description {
    width: 70%;
    max-width: 76rem;
}

.company-description>h2 {
    width: 100%;
    text-align: center;
    font-size: 3.2rem;
    margin: 5rem 0;
}

.company-description p {
    text-align: center;
    font-size: 2rem;
}

.company-description>p>span {
    color: var(--primary)
}

.services-list {
    width: 80%;
    margin: 10rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-item h3 {
    text-align: center;
    color: var(--grayDark);
    font-weight: 500;
}

.service-description {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-description>p {
    width: 80%;
    color: var(--grayDark);
}

.box, .box-mobile {
    width: 15.5rem;
    height: 15.5rem;
    border-radius: 10px;
    background: transparent linear-gradient(180deg, #60A9EF 0%, #1459AF 100%) 0% 0% no-repeat padding-box;
    position: relative;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-mobile {
    border-radius: 10px 0 0 10px !important;
}

.box p {
    max-height: 70%;
    margin: 0;
    position: absolute;
    top: 1.5rem;
    width: 80%;
    font-size: 1.3rem;
}

.box-hovered {
    background: #0f233b !important;
}

.box-icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2rem;
}

.box-icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.arrow-container {
    width: 100%;
    padding-bottom: 1rem;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-item {
    margin: 2rem;
}

.box-wrapper {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
}

.box-wrapper>p {
    width: 70%;
}

@media (max-width: 1200px) {
    .services-list {
        width: 90%;
        flex-wrap: wrap;
        justify-content: center;
    }
}


@media (max-width: 368px) {
    .service-description>p {
        font-size: 1.2rem;
        margin: 0;
    }

    .box-mobile {
        width: 12rem;
        height: 12rem;
    }
}


@media (min-width: 369px) and (max-width: 468px) {
    .service-description>p {
        font-size: 1.4rem;
        margin: 0;
    }

    .box-mobile {
        width: 12rem;
        height: 12rem;
    }
}

@media (min-width: 469px) and (max-width: 576px) {
    .box-mobile {
        width: 14rem;
        height: 14rem;
    }
}

@media (min-width: 577px) and (max-width: 992px) {
    .service-description>p {
        font-size: 1.8rem;
    }
}

@media (max-width: 992px) {
    .service-item h3 {
        font-size: 2.5rem;
        margin: 1.5rem 0;
    }

    .services-list {
        margin: 5rem 0;
    }

    .service-item {
        margin: 0.8rem;
    }

}