.reverse {
    flex-direction: row-reverse !important;
}

.technology-lists-section {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    animation: shrink 10s infinite alternate;
    background-image: url("/public/technologies_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    width: 100%;
    height: 100%;
}

@keyframes shrink {
    0% {
        background-size: 110%;
    }

    100% {
        background-size: 100%;
    }
}

.technology-lists-container {
    max-width: 54.5rem;
    width: 90%;
    margin: 5rem 0;
}


.technology-lists-container>div:first-child {
    margin: 0;
}

.technology-lists-container>div {
    margin-top: 5rem;
}

.technology-list {
    width: 100%;
}

.technology-list-heading-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.technology-list-heading {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.technology-list-heading>h2 {
    max-width: 50%;
    margin: 2rem 0rem;
}

.technology-item-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.technology-item {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.technology-item p {
    width: 60%;
    text-align: center;
    color: #8A8A8A;
}

.technology-icon-xl, .technology-icon-2x {
    filter: invert(69%) sepia(6%) saturate(18%) hue-rotate(325deg) brightness(101%) contrast(82%);
}


.technology-icon-xl {
    width: 1em;
    height: 1em;
    box-shadow: #00000029;
}

.technology-icon-2x {
    width: 2em;
    height: 2em;
}

.technology-list-bullet-point, .technology-bullet-point-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.technology-list-bullet-point, .technology-bullet-point {
    border-radius: 50%;
    display: inline-block;
    box-shadow: 0px 3px 6px #00000029;
}

.technology-bullet-point-container, .technology-list-bullet-point {
    height: 3rem;
    width: 3rem;
}

.technology-list-bullet-point {
    background-color: var(--grayDark);
}

.technology-bullet-point-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.technology-bullet-point {
    height: 1rem;
    width: 1rem;
    background-color: #8A8A8A;
}

@media (max-width: 576px) {
    .technology-lists-container {
        max-width: 37rem;
    }


    .background-image {
        background-repeat: repeat-y;
        background-size: contain;
    }

    .technology-list-heading>h2 {
        font-size: 1.7rem;
        font-weight: bold;
    }

    .technology-item p {
        font-size: 1.3rem;
    }

    .technology-bullet-point-container, .technology-list-bullet-point {
        height: 2rem;
        width: 2rem;
    }

    .technology-bullet-point {
        height: 0.5rem;
        width: 0.5rem;
    }
}

@media (min-width: 579px) and (max-width: 992px) {
    .technology-bullet-point-container, .technology-list-bullet-point {
        height: 2.5rem;
        width: 2.5rem;
    }
}

@media (max-width: 992px) {
    .technology-list-heading>h2 {
        max-width: 100%;
    }
}