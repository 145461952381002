.primary-btn {
    background: transparent linear-gradient(90deg, #0668E1 0%, #479CDE 100%) 0% 0% no-repeat padding-box;
    border: none;
    padding: 1rem;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.primary-btn:hover {
    background: transparent linear-gradient(90deg, #012f67 0%, #479CDE 100%) 0% 0% no-repeat padding-box;
    scale: 1.1;
}