.strengths-section-wrapper {
    height: 65rem;
    background: var(--dark);
    position: relative;
    overflow: hidden;
}

.strengths-section-wrapper>h2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 3.2rem;
}

.strengths-section-wrapper>div {
    width: 100%;
    height: 86%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.strength-item {
    height: 100%;
    width: 100%;
    position: relative;
    color: #fff;
}

.strength-item__image {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.strength-item__box, .strength-item__box-text-container {
    width: 100%;
    height: 25%;
    position: absolute;
    bottom: 0;
    padding: 0 4%;
}

.strength-item__box {
    opacity: 0.5;
}

.strength-item__box-text-container p {
    width: 90%;
}

/* Carousel */
.carousel-container {
    position: relative;
    width: 100%;
    max-width: 120rem;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
}

.carousel-image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.carousel-image-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease;
}

.carousel-description-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 28%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.carousel-description-wrapper h3 {
    width: 75%;
    margin: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 2.5rem;
    color: #fff;
}

.carousel-description-wrapper p {
    width: 75%;
    color: white;
    text-align: left;
    margin: 0;
    padding: 0;
}

.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 2rem;
    padding: 5px;
    cursor: pointer;
}

.carousel-arrow-left {
    left: 10px;
}

.carousel-arrow-right {
    right: 10px;
}