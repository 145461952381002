.introduction-with-images-wrapper {
    width: 100%;
}

.introduction-with-image-row, .introduction-with-image-row-mobile {
    width: 100%;
    min-height: 22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.introduction-with-image-row-mobile {
    background-color: #000;
    z-index: -2;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.row-image, .row-image-mobile {
    min-height: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.row-image-mobile {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.4;
}

.text-mobile {
    width: 80%;
    text-align: center;
    color: #fff;
    font-weight: normal;
}

.row-text-container {
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blueDark);
}

.row-text-container>p {
    width: 80%;
    color: #fff;
}

@media (max-width: 576px) {
    .row-image-mobile {
        transform: scale(1.9);
    }

}