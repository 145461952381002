.contacts-section-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.contacts-section-wrapper h2 {
    color: var(--grayDark);
}

.contacts-section-wrapper>h1 {
    margin: 5rem 3rem;
    font-size: 3.2rem;
    font-weight: 500;
    text-align: center;
}

.contacts-section-wrapper>h2 {
    margin: 5rem 3rem;
    font-size: 2rem;
    text-align: center;
}

.contacts-elements-container {
    max-width: 75rem;
    padding: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 30px;
    margin-bottom: 4rem;
}

.contacts-element {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
}

.contacts-element h3 {
    color: var(--primary);
    margin-bottom: 0;
}

.contacts-element p {
    font-size: 1.5rem;
    text-align: center;
    color: var(--gray);
    padding-bottom: 1.5rem;
}

.contacts-element a {
    color: var(--primary);
    font-size: 2.3rem;
}

.stroke {
    width: 1.5px;
    height: 25vh;
    background-color: #00000029;
    margin: 0 2rem;
}

@media (max-width: 992px) {
    .contacts-elements-container {
        flex-direction: column;
        padding: 5rem;
        border-radius: 1rem;
        margin: 0 2rem;
        margin-bottom: 5rem;
    }

    .stroke {
        width: 70%;
        height: 1.5px;
        margin: 5rem 0;
    }
}

@media (min-width: 769px) and (max-width: 992px) {}


@media (min-width: 577px) and (max-width: 768px) {}

@media (max-width: 576px) {
    .contacts-element a {
        font-size: 1.7rem;
    }
}